import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import SimpleModernHeadline from '../../components/SimpleModernHeadline/SimpleModernHeadline';
import HeadlineWithImage from '../../components/HeadlineWithImage/HeadlineWithImage';

import img_leistung_16 from '../../images/leistungen/img16.jpg';

const LeistungenPage = () => (
  <Layout headerWhite>
    <SEO title="Mikronährstofftherapie | metabolic tuning in Bochum" description="Dr. K. Kostka bietet professionelle Mikronährstofftherapie mitten in Bochum. Moderne Praxis ✓ Top Ausstattung ✓ Vereinbaren Sie jetzt einen Termin ✓"/>
    <Section>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={12}>
            <HeadlineWithImage headline={"Mikronährstofftherapie - metabolic tuning"} subtitle={"Medizin der richtigen Nährstoffe"} frontImage={img_leistung_16}></HeadlineWithImage>
          </Col>
        </Row>
        <p>
          Sprechen wir von Ernährung, so meinen wir im allgemeinen Fette, Kohlenhydrate und Eiweiße- die Makronährstoffe. Unser Stoffwechsel benötigt aber auch sogenannte „Vitalstoffe“- die Mikronährstoffe- wie Vitamine, Spurenelemente und Mineralstoffe, ohne die unser Organismus auf zellulärer Ebene nicht funktionieren würde.
          <br/>
          <br/>
          Ständiger Stress, chronische Erschöpfung, ein zu viel an Sport oder Erkrankungen wie z.B die Fibromyalgie können zu Fehlfunktionen führen. Hier ist der Einsatz von Mikronährstoffen sinnvoll und zeigt gute Behandlungserfolge. Wir setzen unsere Aufbauinfusionen einmal wöchentlich über einen Zeitraum von 4 Wochen ein.
        </p>
      </Container>
    </Section>
  </Layout>
);

export default LeistungenPage;
